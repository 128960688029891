import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-ventana-busqueda',
  templateUrl: './ventana-busqueda.page.html',
  styleUrls: ['./ventana-busqueda.page.scss'],
})
export class VentanaBusquedaPage implements OnInit {
 
  @Input() ResultadoBusqueda;  
  @Input() IdEmpresa;
  @Input() MuestraExistencia;

  constructor(private ModalCtrl: ModalController) { }


  Selecciona(Producto: any){
    this.ModalCtrl.dismiss(
      {
        Resultado: Producto   
      }     
    );
  }


  CierraVentana(){
     
    this.ModalCtrl.dismiss(
      {
        Resultado: {}      
      }     
    );
  }  

  ngOnInit() {
   // console.log('En la modal ' + JSON.stringify(this.ResultadoBusqueda));  
         
  }

}
