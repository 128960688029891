import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-detalle-pedido',
  templateUrl: './detalle-pedido.page.html',
  styleUrls: ['./detalle-pedido.page.scss'],
})
export class DetallePedidoPage implements OnInit {

  @Input() PedidoActual;
  @Input() Detalle;  
  @Input() IdEmpresa;

  TotalItems = 0;

  constructor(private ModalCtrl: ModalController) { }
 
  
  CierraVentana(){
     
    this.ModalCtrl.dismiss(
      {
         
      }     
    );
  }  

  totalIems () {        
    
    for (var i = 0; i < this.Detalle.length; i++) {   
      this.TotalItems = this.TotalItems +  parseInt(this.Detalle[i].CANT);
    }	  
   
  }  	

  ngOnInit() {
    //console.log(JSON.stringify(this.PedidoActual));
     this.totalIems ();
  }

}
