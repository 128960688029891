import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirma-inve-fisico',
  templateUrl: './confirma-inve-fisico.page.html',
  styleUrls: ['./confirma-inve-fisico.page.scss'],
})
export class ConfirmaInveFisicoPage implements OnInit {

  @Input() ProductoActual;

  constructor(private ModalCtrl: ModalController) { }

  
  CierraVentana(){
     
    this.ModalCtrl.dismiss(
      {
        BorraProducto: 'no'  
      }     
    );
  }  

  BorraProducto(){
   // if(this.ProductoActual === '')
    this.ModalCtrl.dismiss(
      {
        BorraProducto: 'si'
      }     
    );
  }

  ngOnInit() {
     console.log('producto:' + this.ProductoActual);
  }

}
