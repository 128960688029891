import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirma-envio-conteo',
  templateUrl: './confirma-envio-conteo.page.html',
  styleUrls: ['./confirma-envio-conteo.page.scss'],
})
export class ConfirmaEnvioConteoPage implements OnInit {

  @Input() ProductoActual;
  @Input() ListaDeContados;

  constructor(private ModalCtrl: ModalController) { }

  
  CierraVentana(){
     
    this.ModalCtrl.dismiss(
      {
        Envia: false  
      }     
    );
  }  

  ConfirmaEnvio(){
   // if(this.ProductoActual === '')
    this.ModalCtrl.dismiss(
      {
        Envia: true  
      }     
    );
  }

  ngOnInit() {
  }

}
